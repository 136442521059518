<template>
  <div id="app">
    <!-- Navigation-->
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <a class="navbar-brand" href="#page-top"><img src="/assets/img/fitenment_white.png"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="fas fa-bars ms-1"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                        <li class="nav-item"><a class="nav-link" href="#features">คุณสมบัติ</a></li>
                        <li class="nav-item"><a class="nav-link" href="#demo">ตัวอย่างโปรแกรม</a></li>
                        <li class="nav-item"><a class="nav-link" href="#about">เกี่ยวกับเรา</a></li>
                        <!-- <li class="nav-item"><a class="nav-link" href="#team">ทีมพัฒนา</a></li>
                        <li class="nav-item"><a class="nav-link" href="#contact">ติดต่อเรา</a></li> -->
                        <li class="nav-item"><a class="nav-link" href="https://app.fitenment.com/"><i class="fa fa-location-arrow fa-inverse"></i> เข้าโปรแกรม</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- Masthead-->
        <header class="masthead">
            <div class="container">
                <div class="masthead-subheading">โปรแกรมที่ช่วยบริหารจัดการธุรกิจยิมและฟิตเนส ที่เหมาะสมกับโลกยุคปัจจุบัน ลงทะเบียนใช้งานได้ฟรี</div>
                <div class="masthead-heading text-uppercase">Fitenment ระบบจัดการฟิตเนส</div>
                <a class="btn btn-primary btn-xl text-uppercase" href="https://app.fitenment.com/th/register"><i class="fa fa-location-arrow fa-inverse"></i> สมัครใช้งาน ฟรี!</a>
            </div>
        </header>
        <!-- Services-->
        <section class="page-section" id="features">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">คุณสมบัติ</h2>
                    <!-- <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3> -->
                    <h4 class="mt-3">ระบบจัดการฟิตเนสบนระบบคราวน์ออนไลน์ อันดับ 1 ในไทย ใช้งานผ่านเว็บ เรียบง่าย ปลอดภัย ไม่ต้องลงโปรแกรม</h4>
                    <h4 class="mt-3 mb-5">เช็คอินเข้าใช้งาน บริหารสมาชิก วันหมดอายุ ต่ออายุ สมัครวันนี้ ใช้งานฟรี</h4>
                </div>
                <div class="row text-center">
                    <div class="col-md-4 mt-3">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-cloud fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">ระบบคราวน์ออนไลน์</h4>
                        <p class="text-muted">สะดวกสบาย ไม่ต้องลงโปรแกรม เข้าใช้งาน ดูรายงาน ได้ทุกที่ทุกเวลา ขอแค่มีอินเตอร์เน็ต</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-laptop fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">ใช้ผ่านเว็บเบราว์เซอร์</h4>
                        <p class="text-muted">ทุกอย่างอยู่บนเว็บ จึงสามารถใช้งานได้บนทุกอุปกรณ์ ทั้งคอมพิวเตอร์ โน้ตบุ๊ค ไอแพด แท็บเล็ต และมือถือ</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-award fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">ออกแบบให้ใช้งานง่าย</h4>
                        <p class="text-muted">โปรแกรมของเราเป็นมิตรกับคุณได้อย่างแน่นอน เรียบๆ ใช้งานง่าย โหลดไว สบายตา</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-rocket fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">พร้อมใช้งานทันที</h4>
                        <p class="text-muted">เปิดใช้งานระบบผ่านการสมัครและยืนยันอีเมล์ ใช้เวลาแค่ 3 นาที ก็มีโปรแกรมดีๆไว้บริหารฟิตเนสของคุณได้ทันที</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fas fa-folder-plus fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">พัฒนาต่อเนื่อง</h4>
                        <p class="text-muted">โปรแกรมจะมีการเพิ่มฟังก์ชั่นใหม่ๆที่ทันสมัย และตอบโจทย์ต่อการบริหารฟิตเนสของคุณอยู่ตลอดเวลา</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-2x text-primary"></i>
                            <i class="fa fa-comments fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 class="my-3">บริการหลังการขาย</h4>
                        <p class="text-muted">เรามีทีมงานคอยดูแลช่วยเหลือ แนะนำและสอนการใช้งานผ่านช่องทางต่างๆ ตลอดการใช้งาน</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- Portfolio Grid-->
        <section class="page-section bg-light" id="demo">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase mb-5">ตัวอย่างโปรแกรม</h2>
                    <!-- <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3> -->
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <!-- <a class="portfolio-link" data-bs-toggle="modal" href="#portfolioModal1">
                                <div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid" src="assets/img/portfolio/1.jpg" alt="..." />
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">Threads</div>
                                <div class="portfolio-caption-subheading text-muted">Illustration</div>
                            </div> -->
                            <a class="portfolio-link">
                                <img class="img-fluid" src="assets/img/app/ftm_1.png" alt="..." />
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">หน้าเพิ่มสมาชิก</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link">
                                <img class="img-fluid" src="assets/img/app/ftm_2.png" alt="..." />
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">หน้าต่ออายุสมาชิก</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link">
                                <img class="img-fluid" src="assets/img/app/ftm_3.png" alt="..." />
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">หน้าเช็คอิน</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link">
                                <img class="img-fluid" src="assets/img/app/ftm_4.png" alt="..." />
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">หน้าประวัติการเช็คอิน</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link">
                                <img class="img-fluid" src="assets/img/app/ftm_5.png" alt="..." />
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">หน้ารายรับของฟิตเนส</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link">
                                <img class="img-fluid" src="assets/img/app/ftm_6.png" alt="..." />
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading">หน้ารายงานบางส่วน</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- About-->
        <section class="page-section" id="about">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">เกี่ยวกับเรา</h2>
                    <h3 class="section-subheading text-muted"></h3>
                </div>
                <ul class="timeline">
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/2.jpg"/></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>แนวทางการพัฒนา</h4>
                                <!-- <h4 class="subheading">Our Humble Beginnings</h4> -->
                            </div>
                            <div class="timeline-body">
                                <p class="text-muted">
                                    โปรแกรม Fitenment ยังมีการอัพเดทระบบ เพิ่มฟังก์ชั่นและจัดการส่วนที่ยังตกหล่น แก้ไขปัญหาการใช้งานและบัคต่างๆ อยู่เรื่อยๆ เพื่อที่จะสร้างโปรแกรมที่มีประสิทธิภาพสูงสุด และทันสมัยมากที่สุดในการทำงาน ผู้ใช้งานสามารถร่วมเป็นส่วนหนึ่งในการพัฒนา แก้ไขและเสนอความคิดเห็นส่วนต่างๆได้
                                </p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/1.jpg"/></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>ความเป็นมา</h4>
                            </div>
                            <div class="timeline-body">
                                <p class="text-muted">
                                    ทีมงาน Fitenment.com เป็นกลุ่ม Startup ที่รวมตัวกันและมีแนวคิดที่จะพัฒนาเทคโนโลยีเพื่อส่งเสริมสุขภาพและความเป็นอยู่ที่ดี
                                    เราเล็งเห็นว่าธุรกิจที่เกี่ยวข้องกับสุขภาพ โดยเฉพาะยิม ฟิตเนสต่างๆ ยังขาดซอฟท์แวร์ในการบริหารจัดการที่มีประสิทธิภาพ และมีราคาที่สมเหตุสมผล ซอฟท์แวร์ช่วยบริหารฟิตเนสที่มีอยู่ในปัจจุบัน ยังไม่มีประสิทธิภาพดีพอ และราคาที่สูงมาก
                                    <br /><br />ดังนั้น เราจึงได้รวบรวมความต้องการ วิเคราะห์ และสร้างโปรแกรมที่ช่วยบริหารจัดการธุรกิจยิมและฟิตเนส ที่เหมาะสมกับโลกยุคปัจจุบัน
                                    หากท่านสนใจใช้งาน สามารถลงทะเบียนใช้งานได้ฟรี ไม่มีค่าใช้จ่ายใดๆ
                                    <br /><br />และหากท่านพบปัญหาการใช้งานใดๆ หรือมีข้อแนะนำติชม สามารถติดต่อเราได้ที่
                                    <br />support@fitenment.com
                                    <br />หรือ LINE ID : @019gsuyw
                                    <br /><br />ขอบคุณสำหรับความไว้ใจในบริการของเรา
                                    <br /><br />ทีมงาน Fitenment.com
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/3.jpg"/></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>ส่วนที่กำลังจะเพิ่ม</h4>
                                <!-- <h4 class="subheading">Our Humble Beginnings</h4> -->
                            </div>
                            <div class="timeline-body">
                                <p class="text-muted">
                                    - ระบบเช็คอินเข้าใช้งานผ่านการสแกนด้วยมือถือ<br />
                                    - เชื่อมต่อกับโปรแกรมบัญชี
                                </p></div>
                        </div>
                    </li>
                    <!-- <li>
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>December 2015</h4>
                                <h4 class="subheading">Transition to Full Service</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>July 2020</h4>
                                <h4 class="subheading">Phase Two Expansion</h4>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image">
                            <h4>
                                Be Part
                                <br />
                                Of Our
                                <br />
                                Story!
                            </h4>
                        </div>
                    </li> -->
                </ul>
            </div>
        </section>
        <!-- Team-->
        <!-- <section class="page-section bg-light" id="team">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Our Amazing Team</h2>
                    <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="assets/img/team/1.jpg" alt="..." />
                            <h4>Parveen Anand</h4>
                            <p class="text-muted">Lead Designer</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="assets/img/team/2.jpg" alt="..." />
                            <h4>Diana Petersen</h4>
                            <p class="text-muted">Lead Marketer</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src="assets/img/team/3.jpg" alt="..." />
                            <h4>Larry Parker</h4>
                            <p class="text-muted">Lead Developer</p>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 mx-auto text-center"><p class="large text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde.</p></div>
                </div>
            </div>
        </section> -->
        <!-- Clients-->
        <div class="py-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="assets/img/logos/google_cloud.png" alt="Google Cloud" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="assets/img/logos/firebase.png" alt="Firebase" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="assets/img/logos/nodejs.png" alt="Node JS" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="assets/img/logos/vuejs.png" alt="Vue.js" /></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact-->
        <section class="page-section" id="contact">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Fitenment เปิดให้สมัครใช้งานฟรี หากท่านสนใจ สามารถสมัครใช้งานได้ทันที</h2>
                    <h3 class="section-subheading text-muted"></h3>
                </div>
                <!-- * * * * * * * * * * * * * * *-->
                <!-- * * SB Forms Contact Form * *-->
                <!-- * * * * * * * * * * * * * * *-->
                <!-- This form is pre-integrated with SB Forms.-->
                <!-- To make this form functional, sign up at-->
                <!-- https://startbootstrap.com/solution/contact-forms-->
                <!-- to get an API token!-->
                <!-- <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                    <div class="row align-items-stretch mb-5">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" id="name" type="text" placeholder="Your Name *" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                            </div>
                            <div class="form-group">
                                <input class="form-control" id="email" type="email" placeholder="Your Email *" data-sb-validations="required,email" />
                                <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                                <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                            </div>
                            <div class="form-group mb-md-0">
                                <input class="form-control" id="phone" type="tel" placeholder="Your Phone *" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-textarea mb-md-0">
                                <textarea class="form-control" id="message" placeholder="Your Message *" data-sb-validations="required"></textarea>
                                <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none" id="submitSuccessMessage">
                        <div class="text-center text-white mb-3">
                            <div class="fw-bolder">Form submission successful!</div>
                            To activate this form, sign up at
                            <br />
                            <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                        </div>
                    </div>
                    <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error sending message!</div></div>
                    <div class="text-center"><button class="btn btn-primary btn-xl text-uppercase disabled" id="submitButton" type="submit">Send Message</button></div>
                </form> -->
                <div class="text-center">
                    <a class="btn btn-primary btn-xl text-uppercase" href="https://app.fitenment.com/th/register"><i class="fa fa-location-arrow fa-inverse"></i> สมัครใช้งาน ฟรี!</a>
                </div>
            </div>
        </section>
        <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start">Copyright &copy; Fitenment.com 2021.</div>
                    <div class="col-lg-4 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/fitenment"><i class="fab fa-facebook-f"></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end">
                        <a class="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                        <a class="link-dark text-decoration-none" href="#!">Terms of Use</a><br />
                        <a href='https://www.freepik.com/'>photo created by freepik - www.freepik.com</a>
                    </div>
                </div>
            </div>
        </footer>
        <!-- Portfolio Modals-->
        <!-- Portfolio modal popup-->
        <!-- <div class="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <h2 class="text-uppercase">Project Name</h2>
                                    <p class="item-intro text-muted">Lorem ipsum dolor sit amet consectetur.</p>
                                    <img class="img-fluid d-block mx-auto" src="assets/img/portfolio/1.jpg" alt="..." />
                                    <p>Use this area to describe your project. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est blanditiis dolorem culpa incidunt minus dignissimos deserunt repellat aperiam quasi sunt officia expedita beatae cupiditate, maiores repudiandae, nostrum, reiciendis facere nemo!</p>
                                    <ul class="list-inline">
                                        <li>
                                            <strong>Client:</strong>
                                            Threads
                                        </li>
                                        <li>
                                            <strong>Category:</strong>
                                            Illustration
                                        </li>
                                    </ul>
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-times me-1"></i>
                                        Close Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>
